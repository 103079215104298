<template>
  <div class="v-stack gap-3 h-stretch" v-if="project">
    <div ref="overlay" class="overlay">
      <div class="card dynamic">
        <div class="content mt-3">
          <div class="v-stack h-stretch gap-5">
            <div>Are you sure you want to delete/archive this project?</div>
          </div>
        </div>
        <div class="footer gap-3 h-space-between mt-3">
          <button @click="dismiss()" class="submit">Cancel</button>
          <button @click="archive()" class="edit">Delete</button>
        </div>
      </div>
    </div>
    <div class="card light border h-stretch">
      <div class="content h-stretch">
        <form class="gap-3 h-stretch">
          <a class="heading-title-3">Info</a>
          <label class="text-left required">Title</label>
          <input v-model="project.title" type="text" />
          <label class="text-left">Sub Title</label>
          <input v-model="project.subtitle" type="text" />
          <label class="text-left required">Icon</label>
          <select v-model="project.icon">
            <option v-for="icon in icons" :key="icon">{{ icon }}</option>
          </select>
          <label class="text-left required">Deadline</label>
          <InputDatePicker v-model="project.deadline"></InputDatePicker>
          <label class="text-left required">State</label>
          <select v-model="project.state">
            <option v-for="state in states" :key="state">{{ state }}</option>
          </select>
          <!-- <input v-model="project.vimeo" type="text" /> -->
          <label class="text-left">Size (GB)</label>
          <input v-model="project.size" type="number" />
          <label class="text-left">Project description</label>
          <textarea v-model="project.description"></textarea>
          <a class="heading-title-3">Links</a>
          <div class="pane-left gap-3">
            <label
              class="text-left"
              style="width: 300px; align-self: center"
              placeholder="name"
              >Name:</label
            >
            <div class="pane-right gap-3">
              <label
                class="text-left"
                style="width: 300px; align-self: center"
                placeholder="name"
                >URL:</label
              >
              <button class="add" @click.prevent="addLink()">Add Link</button>
            </div>
          </div>
          <div
            class="pane-left gap-3"
            v-for="link in project.links"
            :key="link._id"
          >
            <input
              type="text"
              style="width: 300px"
              placeholder="name"
              v-model="link.name"
            />
            <div class="pane-right gap-3">
              <input type="text" placeholder="url" v-model="link.url" />
              <button class="edit" @click.prevent="removeLink(link._id)">
                x
              </button>
            </div>
          </div>
          <a class="heading-title-3">Contact</a>
          <label class="text-left">Name</label>
          <input v-model="project.contactName" type="text" />
          <label class="text-left">Phone</label>
          <input v-model="project.contactPhone" type="text" />
          <label class="text-left">Email</label>
          <input v-model="project.contactEmail" type="email" />
          <div class="h-stack h-space-between">
            <button class="edit" @click.prevent="archiveDialog()">
              Archive (delete)
            </button>
            <button class="submit" @click.prevent="submit()">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import constants from "@/constants.js";
import { v4 as uuidv4 } from "uuid";
import InputDatePicker from "@/components/calendar/InputDatePicker.vue";

export default {
  data() {
    return {
      states: constants.projectState,
      project: null,
      icons: constants.projectIcons,
    };
  },
  components: {
    InputDatePicker,
  },
  props: ["id"],
  methods: {
    ...mapActions(["getProject", "editProject", "archiveProject"]),
    formatDate(dateString, format) {
      const date = moment(dateString);
      return date.format(format);
    },
    addLink() {
      if (this.project) {
        this.project.links.push({
          name: "",
          url: "",
          _id: uuidv4(),
        });
      }
    },
    removeLink(id) {
      if (this.project) {
        for (const [i, link] of this.project.links.entries()) {
          if (link._id == id) {
            this.project.links.splice(i, 1);
            return;
          }
        }
      }
    },
    archiveDialog() {
      this.$refs.overlay.classList.add("show");
    },
    dismiss() {
      this.$refs.overlay.classList.remove("show");
    },
    archive() {
      this.archiveProject(this.id)
        .then(() => {
          this.$router.push("/projects/");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    refresh() {
      this.getProject(this.id)
        .then((project) => {
          this.project = project;
          // this.project.size = this.project.size * 1000;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submit() {
      if (this.project) {
        // this.project.size = this.project.size / 1000;
        this.editProject(this.project)
          .then(() => {
            this.$router.go(-1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>